import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  FormHelperText,
  Tooltip,
  Button,
  Alert,
} from '@mui/material';
import DocumentField from '../index.jsx';
import useRedux from '../../../../utils/hooks/useRedux';
import {
  areAllElementsSame,
  errorChecker,
  fetchSourceData,
  findElement,
  findElements,
  modifyJson,
  randomUUID,
  updateJsonIds,
} from '../../../../utils/helpers.js';
import { Help, InfoOutlined } from '@mui/icons-material';
import ValueSelectionModal from '../../../questions/home/ValueSelectionModal.jsx';
import styled from '@emotion/styled';
import {
  UTILITY_RADIO_ELEMENT_ID,
  CREDIT_CARD_PARENT_ID,
  ADDITIONAL_SELLER_PARENT_ID,
  HOA_PARENT_ID,
  HOA_SECTION_ID,
  HOA_GROUP_ID,
  HOA_MAIN_PARENT_IDX,
  BUYER_PARENT,
  BUYER_SECTION,
  BUYER_PARENT_OPTION,
  BUYER_GROUP,
  SOI_SECTION_ID,
  ADDITIONAL_SELLER_GROUP_ID,
  ADDITIONAL_SELLER_OPTION_ID,
  POA_ID,
  LOAN_2,
  LENDER_INFO_ID,
  LENDER_GROUP,
  MAIN_LOAN_ELEM,
  MAILING_ADDRESS_QUESTION,
  MAILING_ADDRESS_ADDRESS,
  MAILING_ADDRESS_CITY,
  MAILING_ADDRESS_ZIP,
  MAILING_ADDRESS_STATE,
} from '../../../../utils/constants';
import {
  addConvertedTexts,
  addCreditCardInfo,
  addDlDetails,
  addQuestionDetails,
  additionalParticipantToggle,
} from '../../../../store/slices/questionSlice';
import Stripe from '../../../questions/home/Stripe';
import CardComponent from '../../../questions/home/CardComponent';
import Loader from '../../Loader.jsx';
import { cloneDeep } from 'lodash';
import hoa from '../../../../utils/HOA.json';
import { verifyElement } from '../../../../services/admin/participantForms.js';
import { getQuestionDetails, translateContent } from '../../../../services/questions/home.js';
import { toast } from 'react-toastify';
import CommonModal from '../../../questions/home/CommonModal';
import additionalBuyer from '../../../../utils/additionalBuyer.json';
import additionalBorrowerVss from '../../../../utils/additionalBorrowerVss.json';
import loans from '../../../../utils/loans.json';
import { useTranslation } from 'react-i18next';

const loanUploadField = {
  label:
    'Please upload a copy of your last statement. If you choose to NOT upload, please complete the required form fields below.',
  display: true,
  help_id: '0',
  options: [],
  required: false,
  change_log: [],
  element_id: '1931aca8-d550-4000-88b1-294e9df3ba00',
  user_value: null,
  element_name: 'core_last_statement',
  element_type: 'U',
  verification: {
    type: 'static',
    verification_source: [],
  },
  match_pattern: null,
  sample_input: null,
  confirmed_value: [],
};

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    padding: 8px;
    background: #686565;
    color: white;
  }
`;

const RadioButton = ({ element, orderId, disabled, setShowUtilityBtn, saveFormDataPHH }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { code, participantNo } = useParams();
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code];
  const languageForLabel = selectedLanguage && selectedLanguage !== 'en' ? selectedLanguage : '';
  const authData = useSelector((state) => state?.authSlice);
  const isBorrower = authData?.[code]?.participant_type_name?.toLowerCase().includes('borrower');
  const isBuyer = authData?.[code]?.participant_type_name?.toLowerCase().includes('buyer');
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  const dlDetails = useSelector((state) => state.questionSlice.dlDetails)?.[code];

  const isDisableForm = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.is_pdf_generated;
  const creditCardInformation = useSelector((state) => state.questionSlice.creditCardInfo)?.find(
    (que) => que.tx_id === code
  );
  const question = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.questions;
  const isSignatureTitle = authData?.[code]?.is_signature_title;

  const { setFormValue, getFormValue, getErrorDetails, removeError, setError, setTouched } =
    useRedux();
  const value = getFormValue(element.element_id);
  const { error } = getErrorDetails(element.element_id) || {
    error: false,
    error_message: '',
  };
  const [radioValue, setRadioValue] = useState(0);
  const [sourceList, setSourceList] = useState([]);
  const [open, setOpen] = useState(false);
  const [disabledField, setDisabledField] = useState(false);
  const [stripeModalOpen, setStripeModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showBuyerConfirmation, setShowBuyerConfirmation] = useState(false);
  const [showBorrowerConfirmation, setShowBorrowerConfirmation] = useState(false);
  const [showLoanConfirmation, setShowLoanConfirmation] = useState(false);
  const dataVal = value !== undefined ? value : element.user_value ? element.user_value : '';
  const isAdditionalParticipant = participantNo && participantNo != 1 ? true : false;

  useEffect(() => {
    setSourceList(fetchSourceData(element));
  }, [element]);

  useEffect(() => {
    if (
      (sourceList?.length === 1 || areAllElementsSame(sourceList.map((source) => source?.value))) &&
      !element.user_value
    ) {
      if (element?.options?.map((opt) => opt.option_name)?.includes(sourceList[0]?.value)) {
        setRadioValue(sourceList[0]?.value);
        setFormValue({ id: element.element_id, value: sourceList[0]?.value });
        // setDisabledField(true);
      }
    }
  }, [sourceList]);

  useEffect(() => {
    if (value !== undefined) setRadioValue(value);
    else if (element.user_value) setRadioValue(element.user_value);
  }, [value]);

  const handleChange = (val) => {
    if (element.element_id === UTILITY_RADIO_ELEMENT_ID) {
      if (val === 'Yes') {
        setShowUtilityBtn(true);
      } else if (val === 'No') {
        setShowUtilityBtn(false);
      }
    }
    // if (element.element_id === CREDIT_CARD_PARENT_ID) {
    //   if (val === 'Yes') {
    //     dispatch(
    //       addCreditCardInfo({ show: true, info: creditCardInformation?.info || {}, tx_id: code })
    //     );
    //   } else if (val === 'No') {
    //     dispatch(addCreditCardInfo({ show: false, info: {}, tx_id: code }));
    //   }
    // }
    if (element.element_id === ADDITIONAL_SELLER_PARENT_ID) {
      if (val === 'Yes') {
        dispatch(
          additionalParticipantToggle({
            value: true,
            tx_id: code,
          })
        );
      } else if (val === 'No') {
        dispatch(
          additionalParticipantToggle({
            value: false,
            tx_id: code,
          })
        );
      }
    }
    setTouched(element.element_id);
    setRadioValue(val);
    setFormValue({ id: element.element_id, value: val });
    handleError(val);
    setOpen(false);
  };

  const handleError = async (value) => {
    setIsLoading(true);
    const { isError, message } = errorChecker(element, value);
    if (!isError) {
      removeError(element.element_id);
    } else {
      setError({ id: element.element_id, error: true, error_message: message });
    }
    // to handle error for mailing address using moby's response
    // if ([MAILING_ADDRESS_QUESTION].includes(element.element_id)) {
    //   const userAddr = getUserAddress(value);
    //   if (Object.keys(userAddr || {})?.length && dlDetails?.transaction_response?.CustomerAddress) {
    //     console.log(userAddr, dlDetails?.transaction_response?.CustomerAddress);
    //     const addressResult = await matchAddress(
    //       dlDetails?.transaction_response?.CustomerAddress,
    //       userAddr,
    //       authData?.token
    //     );
    //     let data = {
    //       ...dlDetails,
    //       errors: addressResult,
    //     };
    //     dispatch(addDlDetails({ tx_id: code, data: { ...data } }));
    //   }
    // }
    setIsLoading(false);
  };

  const getString = () => {
    return sourceList.map((source) => source.value).join(' or ');
  };

  const getQuestionData = async () => {
    setIsLoading(true);
    const queData = await getQuestionDetails(code, authData?.[code]?.token);
    if (queData?.success) {
      dispatch(
        addQuestionDetails({
          tx_id: code,
          is_pdf_generated: queData?.response?.is_pdf_generated || false,
          questions: queData?.response?.question?.cached_questions_json?.question,
          template_id: queData?.response?.template_id,
          sources: queData?.response?.source || [],
          order_part_participant_id: queData?.response?.question?.id,
          documents: queData?.response?.additional_docs || {},
        })
      );
    } else {
      toast.error(queData?.message || 'Something went wrong!');
    }
    setIsLoading(false);
  };

  const currentParticipantFinder = () => {
    if (isBuyer) {
      const section = question.sections.find((section) => section.section_id == BUYER_SECTION);
      const mainElem = findElement(section, BUYER_PARENT);
      const elem = mainElem?.options?.[0]?.elements;
      const lastElem = elem?.[elem?.length - 1];
      return lastElem?.participant_number ? +lastElem?.participant_number : 2;
    } else if (isBorrower) {
      const section = question.sections.find((section) => section.section_id == SOI_SECTION_ID);
      const mainElem = findElement(section, ADDITIONAL_SELLER_PARENT_ID);
      const elem = mainElem?.options?.[0]?.elements;
      const lastElem = elem?.[elem?.length - 1];
      return lastElem?.participant_number ? +lastElem?.participant_number : 2;
    } else {
      return 2;
    }
  };

  const currenLoanFinder = () => {
    const section = question.sections.find((section) => section.section_id == LENDER_INFO_ID);
    const mainElem = findElement(section, LOAN_2);
    const elem = mainElem?.options?.[0]?.elements;
    const lastElem = elem?.[elem?.length - 1];
    return lastElem?.loan_sequence_no ? +lastElem?.loan_sequence_no : 2;
  };

  /* Need to refactor this code for adding additional participants and HOA */

  const translateContentAndUpdate = async (elementsForTranslations, options) => {
    try {
      const alreadyTranslatedLanguages = Object.keys(translations || {});
      for (const language of alreadyTranslatedLanguages) {
        if (language === 'tx_id') continue;
        let translatedContentElements = {};
        let translatedContentOptions = {};
        if (elementsForTranslations?.length) {
          const body = {
            text: elementsForTranslations.map((elem) => elem.label),
            target: language,
          };
          const res = await translateContent(body, authData?.[code]?.token);
          if (res.success) {
            const staticTranslations = res?.response?.data?.translations?.map(
              (translation) => translation.translatedText
            );
            staticTranslations.forEach((translatedLabel, index) => {
              translatedContentElements[elementsForTranslations[index]['id']] = translatedLabel;
            });
          }
        }
        if (options?.length) {
          const body = {
            text: options.map((elem) => elem.label),
            target: language,
          };
          const res = await translateContent(body, authData?.[code]?.token);
          if (res.success) {
            const staticTranslations = res?.response?.data?.translations?.map(
              (translation) => translation.translatedText
            );
            staticTranslations.forEach((translatedLabel, index) => {
              translatedContentOptions[options[index]['id']] = translatedLabel;
            });
          }
        }
        dispatch(
          addConvertedTexts({
            tx_id: code,
            language: language,
            translations: {
              ...translations?.[language],
              elements: { ...translations?.[language]?.elements, ...translatedContentElements },
              options: { ...translations?.[language]?.options, ...translatedContentOptions },
            },
          })
        );
      }
    } catch (e) {
      toast.error(
        'Something went wrong! We were unable to translate the new fields into your selected language.'
      );
      await getQuestionData();
    }
  };
  const addParticipants = async () => {
    try {
      setIsLoading(true);
      let tempJson = cloneDeep(question);
      let currentParticipantsAdded = currentParticipantFinder();
      let tempElements = cloneDeep(additionalBuyer);
      for (const tempElement of tempElements) {
        tempElement.by_participant = 'Y';
        tempElement.participant_number = currentParticipantsAdded + 1;
        tempElement.element_name = tempElement.element_name + (currentParticipantsAdded + 1);
        tempElement.element_id = randomUUID();
      }
      const updatedJson = modifyJson(
        tempJson,
        tempElements,
        BUYER_SECTION,
        BUYER_GROUP,
        BUYER_PARENT,
        BUYER_PARENT_OPTION
      );

      if (updatedJson) {
        const res = await verifyElement(
          code,
          {
            cached_questions_json: {
              question: cloneDeep(updatedJson),
            },
            is_completed: false,
          },
          authData?.[code]?.token
        );
        if (res?.success) {
          let contentToTranslate = findElements(tempElements);
          await translateContentAndUpdate(
            contentToTranslate?.elements || [],
            contentToTranslate?.options || []
          );
          await getQuestionData(code);
        } else {
          toast.error(res?.message);
        }
      } else {
        toast.error(t('somethingWrong'));
      }
      setShowBuyerConfirmation(false);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      toast.error(t('somethingWrong'));
    }
  };

  const addHoa = async () => {
    try {
      setIsLoading(true);
      let tempJson = cloneDeep(question);
      let tempElements = cloneDeep(hoa);
      for (const tempElement of tempElements) {
        tempElement.by_participant = 'Y';
        tempElement.element_id = randomUUID();
        tempElement.element_name = 'participant_field_' + Math.floor(10000 + Math.random() * 90000);
      }
      tempElements = updateJsonIds(tempElements);
      // We will optimize this logic to handle below things dynamically
      let sectionIdx = tempJson?.sections?.findIndex(
        (section) => section.section_id === HOA_SECTION_ID
      );
      if (sectionIdx == -1) return toast.error('Something went wrong! Please try again');
      let grpIdx = tempJson?.sections?.[sectionIdx]?.groups?.findIndex(
        (grp) => grp.group_id === HOA_GROUP_ID
      );
      if (grpIdx == -1) return toast.error('Something went wrong! Please try again');
      let mainParenIdx = tempJson?.sections?.[sectionIdx]?.groups?.[grpIdx]?.elements?.findIndex(
        (elm) => elm.element_id === HOA_MAIN_PARENT_IDX
      );
      if (mainParenIdx == -1) return toast.error('Something went wrong! Please try again');
      let secondParentIdx = tempJson?.sections?.[sectionIdx]?.groups?.[grpIdx]?.elements[
        mainParenIdx
      ]?.options[0]?.elements?.findIndex((elm) => elm.element_id === HOA_PARENT_ID);
      if (secondParentIdx == -1) return toast.error('Something went wrong! Please try again');
      tempJson.sections[sectionIdx].groups[grpIdx].elements[mainParenIdx].options[0].elements[
        secondParentIdx
      ].options[0].elements.push(...tempElements);

      const res = await verifyElement(
        code,
        {
          cached_questions_json: {
            question: cloneDeep(tempJson),
          },
          is_completed: false,
        },
        authData?.[code]?.token
      );
      if (res?.success) {
        let contentToTranslate = findElements(tempElements);
        await translateContentAndUpdate(
          contentToTranslate?.elements || [],
          contentToTranslate?.options || []
        );
        await getQuestionData();
      } else {
        toast.error(res?.message);
      }
      setShowConfirmation(false);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setShowConfirmation(false);
      setIsLoading(false);
      toast.error('Something went wrong!');
    }
  };

  const addLoans = async () => {
    try {
      setIsLoading(true);
      let tempJson = cloneDeep(question);
      let tempElements = cloneDeep(loans);
      if (isSignatureTitle) {
        const index = tempElements.findIndex((item) => item.element_name === 'core_lender_equity');
        if (index !== -1) {
          tempElements.splice(index + 1, 0, loanUploadField);
        }
      }
      for (const tempElement of tempElements) {
        tempElement.by_participant = 'Y';
        tempElement.element_id = randomUUID();
        tempElement.element_name = tempElement.element_name + '_' + (currenLoanFinder() + 1);
        tempElement.loan_sequence_no = currenLoanFinder() + 1;
      }
      tempElements = updateJsonIds(tempElements);

      // We will optimize this logic to handle below things dynamically
      let sectionIdx = tempJson?.sections?.findIndex(
        (section) => section.section_id === LENDER_INFO_ID
      );
      if (sectionIdx == -1) {
        setIsLoading(false);
        return toast.error('Something went wrong! Please try again');
      }
      let grpIdx = tempJson?.sections?.[sectionIdx]?.groups?.findIndex(
        (grp) => grp.group_id === LENDER_GROUP
      );
      if (grpIdx == -1) {
        setIsLoading(false);
        return toast.error('Something went wrong! Please try again');
      }

      let mainParenIdx = tempJson?.sections?.[sectionIdx]?.groups?.[grpIdx]?.elements?.findIndex(
        (elm) => elm.element_id === MAIN_LOAN_ELEM
      );
      if (mainParenIdx == -1) {
        setIsLoading(false);
        return toast.error('Something went wrong! Please try again');
      }
      let secondParentIdx = tempJson?.sections?.[sectionIdx]?.groups?.[grpIdx]?.elements[
        mainParenIdx
      ]?.options[0]?.elements?.findIndex((elm) => elm.element_id === LOAN_2);
      if (secondParentIdx == -1) return toast.error('Something went wrong! Please try again');
      tempJson.sections[sectionIdx].groups[grpIdx].elements[mainParenIdx].options[0].elements[
        secondParentIdx
      ].options[0].elements.push(...tempElements);
      const res = await verifyElement(
        code,
        {
          cached_questions_json: {
            question: cloneDeep(tempJson),
          },
          is_completed: false,
        },
        authData?.[code]?.token
      );
      if (res?.success) {
        let contentToTranslate = findElements(tempElements);
        await translateContentAndUpdate(
          contentToTranslate?.elements || [],
          contentToTranslate?.options || []
        );
        await getQuestionData();
      } else {
        toast.error(res?.message);
      }
      setShowLoanConfirmation(false);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setShowLoanConfirmation(false);
      setIsLoading(false);
      toast.error('Something went wrong!');
    }
  };

  const addBorrowers = async () => {
    setIsLoading(true);
    let tempJson = cloneDeep(question);
    let currentParticipantsAdded = currentParticipantFinder();
    let tempElements = cloneDeep(additionalBorrowerVss);
    for (const tempElement of tempElements) {
      tempElement.by_participant = 'Y';
      tempElement.participant_number = currentParticipantsAdded + 1;
      tempElement.element_name = tempElement.element_name + (currentParticipantsAdded + 1);
      tempElement.element_id = randomUUID();
    }
    const updatedJson = modifyJson(
      tempJson,
      tempElements,
      SOI_SECTION_ID,
      ADDITIONAL_SELLER_GROUP_ID,
      ADDITIONAL_SELLER_PARENT_ID,
      ADDITIONAL_SELLER_OPTION_ID
    );
    if (updatedJson) {
      const res = await verifyElement(
        code,
        {
          cached_questions_json: {
            question: cloneDeep(updatedJson),
          },
          is_completed: false,
        },
        authData?.[code]?.token
      );
      if (res?.success) {
        let contentToTranslate = findElements(tempElements);
        await translateContentAndUpdate(
          contentToTranslate?.elements || [],
          contentToTranslate?.options || []
        );
        await getQuestionData(code);
        setShowBorrowerConfirmation(false);
      } else {
        toast.error(res?.message);
      }
    } else {
      toast.error('Something went wrong! Please try again');
    }
    setIsLoading(false);
  };
  const populateAddressFields = () => {
    const mobyAddrObj = dlDetails?.errors?.moby_address || {};
    if (Object.keys(mobyAddrObj)?.length) {
      const isSameAsPropetyAddress = dataVal === 'Property';
      if (isSameAsPropetyAddress) {
        setFormValue({
          id: MAILING_ADDRESS_QUESTION,
          value: 'Other',
        });
        setTouched(MAILING_ADDRESS_QUESTION);
      }
      setFormValue({
        id: MAILING_ADDRESS_ADDRESS,
        value: mobyAddrObj?.site_address || '',
      });
      setTouched(MAILING_ADDRESS_ADDRESS);
      setFormValue({
        id: MAILING_ADDRESS_CITY,
        value: mobyAddrObj?.site_city || '',
      });
      setTouched(MAILING_ADDRESS_CITY);
      setFormValue({
        id: MAILING_ADDRESS_ZIP,
        value: mobyAddrObj?.site_zip || '',
      });
      setTouched(MAILING_ADDRESS_ZIP);
      setFormValue({
        id: MAILING_ADDRESS_STATE,
        value: mobyAddrObj?.site_state || '',
      });
      setTouched(MAILING_ADDRESS_STATE);
      dispatch(
        addDlDetails({
          tx_id: code,
          data: {
            ...dlDetails,
            errors: {
              [MAILING_ADDRESS_ADDRESS]: false,
              [MAILING_ADDRESS_STATE]: false,
              [MAILING_ADDRESS_CITY]: false,
              [MAILING_ADDRESS_ZIP]: false,
              state: dlDetails?.errors?.state,
              moby_address: dlDetails?.errors?.moby_address,
            },
          },
        })
      );
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      {!isAdditionalParticipant &&
      element.element_id === MAILING_ADDRESS_QUESTION &&
      dlDetails?.errors?.[MAILING_ADDRESS_ZIP] ? (
        <>
          <Alert
            severity='warning'
            className='f-13 mb-8 fw-450 w-100p'
            style={{ padding: '2px 10px' }}
          >
            <Grid container>
              <Grid item md={12} xs={12}>
                {t('mailingAddressError')}
              </Grid>
            </Grid>
          </Alert>
          <Grid item md={12} xs={12}>
            <div className='d-flex justify-end w-100p'>
              <Button
                disabled={disabled}
                variant='outlined'
                onClick={populateAddressFields}
                style={{ display: 'flex', height: '30px', marginBottom: '12px' }}
              >
                {t('fetchAdr')}
              </Button>
            </div>
          </Grid>
        </>
      ) : (
        ''
      )}
      <Stripe open={stripeModalOpen} setOpen={setStripeModalOpen} />
      <Grid xs={12} md={12} className='grid-container radio-padding radio-btn-field light-bg-field'>
        <Grid xs={12} md={element?.options?.length > 2 ? 8 : 8.5} item={true}>
          <label
            dangerouslySetInnerHTML={{
              __html: languageForLabel
                ? translations?.[languageForLabel]?.elements?.[element.element_id]
                  ? translations?.[languageForLabel]?.elements?.[element.element_id]
                  : element?.label
                : element?.label,
            }}
          />
          <label className='required'>{element.required && '*'}</label>{' '}
          {element?.help_note ? (
            <Tooltip title={element?.help_note}>
              {' '}
              <Help className='f-14 mt-minus-2 cursor-pointer' />
            </Tooltip>
          ) : (
            ''
          )}
        </Grid>

        <Grid
          xs={12}
          md={element?.options?.length > 2 ? 4 : 3.5}
          item={true}
          className='radio-container'
        >
          <RadioGroup
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='radio-button-demo'
          >
            {element.options.map((option, idx) => {
              return (
                <StyledTooltip
                  arrow
                  placement='top'
                  title={
                    sourceList?.length > 1 &&
                    !areAllElementsSame(sourceList.map((source) => source?.value)) ? (
                      <div className='d-flex justify-center align-items-center'>
                        <InfoOutlined fontSize='small' className='mr-4' />
                        <div>{`${getString() + ' is suggested value.'}`}</div>
                      </div>
                    ) : (
                      ''
                    )
                  }
                >
                  <div key={idx}>
                    {error ? (
                      <FormControlLabel
                        checked={radioValue === option.option_name}
                        onChange={(e) => {
                          handleChange(e.target.value);
                        }}
                        value={option.option_name}
                        control={
                          <Radio
                            sx={{
                              color: 'red',
                              '&.Mui-checked': {
                                color: 'red',
                              },
                            }}
                          />
                        }
                        label={
                          languageForLabel
                            ? translations?.[languageForLabel]?.options?.[option.option_id]
                              ? translations?.[languageForLabel]?.options?.[option.option_id]
                              : option?.option_name
                            : option?.option_name
                        }
                        className='error'
                        disabled={disabled || disabledField || element?.disabled}
                      >
                        {languageForLabel
                          ? translations?.[languageForLabel]?.options?.[option.option_id]
                            ? translations?.[languageForLabel]?.options?.[option.option_id]
                            : option?.option_name
                          : option?.option_name}
                      </FormControlLabel>
                    ) : (
                      <FormControlLabel
                        checked={radioValue === option.option_name}
                        onChange={(e) => {
                          handleChange(e.target.value);
                        }}
                        value={option.option_name}
                        control={<Radio />}
                        label={
                          languageForLabel
                            ? translations?.[languageForLabel]?.options?.[option.option_id]
                              ? translations?.[languageForLabel]?.options?.[option.option_id]
                              : option?.option_name
                            : option?.option_name
                        }
                        disabled={disabled || disabledField || element?.disabled}
                      >
                        {languageForLabel
                          ? translations?.[languageForLabel]?.options?.[option.option_id]
                            ? translations?.[languageForLabel]?.options?.[option.option_id]
                            : option?.option_name
                          : option?.option_name}
                      </FormControlLabel>
                    )}
                  </div>
                </StyledTooltip>
              );
            })}
            {/* {sourceList?.length > 1 &&
            !areAllElementsSame(sourceList.map((source) => source?.value)) ? (
              <Tooltip title='Click to Select value from given list'>
                <IconButton
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Tune style={{ color: 'grey', marginLeft: '2px' }} fontSize='small' />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )} */}
          </RadioGroup>
        </Grid>
      </Grid>
      {/* <FormHelperText error={true} className='w-100p d-flex justify-end mt-0'>
        {error_message || ''}
      </FormHelperText> */}

      {element.element_id === POA_ID ? (
        <div className='f-12 pl-8 fw-500 color-g'>{t('notePOA')}</div>
      ) : (
        ''
      )}

      <ValueSelectionModal
        setOpen={setOpen}
        open={open}
        data={sourceList}
        onSelect={handleChange}
        value={radioValue}
      />
      {/* {creditCardInformation?.show && element.element_id === CREDIT_CARD_PARENT_ID ? (
        <div className='d-flex justify-end plt-8 w-100p'>
          <Button
            variant='outlined'
            onClick={() => {
              setStripeModalOpen(true);
            }}
            style={{ display: 'flex', height: '30px', marginBottom: '12px' }}
            disabled={disabled}
          >
            {t('addCard')}
          </Button>
        </div>
      ) : (
        ''
      )} */}
      {/* {creditCardInformation?.show && element.element_id === CREDIT_CARD_PARENT_ID ? (
        <div style={{ width: '100%' }}>
          {creditCardInformation?.info?.id ? (
            <CardComponent info={creditCardInformation?.info} />
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )} */}

      {element.options &&
        element.options.map(
          (option, idx) =>
            option.option_name == radioValue && (
              <React.Fragment key={idx}>
                {option.elements.map((element, index) => {
                  return (
                    <DocumentField
                      key={index}
                      element={element}
                      orderId={orderId}
                      disabled={isDisableForm}
                      saveFormDataPHH={saveFormDataPHH}
                    />
                  );
                })}
              </React.Fragment>
            )
        )}
      {/* Need to refactor this code for adding additional participants and HOA */}

      {element.element_id === HOA_PARENT_ID && radioValue === 'Yes' ? (
        <div className='d-flex justify-end plt-8 w-100p'>
          <Button
            variant='outlined'
            style={{ display: 'flex', height: '30px', marginBottom: '12px' }}
            onClick={() => {
              setShowConfirmation(true);
            }}
            disabled={disabled}
          >
            {t('addHOA')}
          </Button>
        </div>
      ) : (
        ''
      )}
      {element.element_id === LOAN_2 && radioValue === 'Yes' ? (
        <div className='d-flex justify-end plt-8 w-100p'>
          <Button
            variant='outlined'
            style={{ display: 'flex', height: '30px', marginBottom: '12px' }}
            onClick={() => {
              setShowLoanConfirmation(true);
            }}
            disabled={disabled}
          >
            {t('addLoan')}
          </Button>
        </div>
      ) : (
        ''
      )}
      {element.element_id === BUYER_PARENT && radioValue === 'Yes' ? (
        <div className='d-flex justify-end plt-8 w-100p'>
          <Button
            variant='outlined'
            style={{ display: 'flex', height: '30px', marginBottom: '12px' }}
            onClick={() => {
              setShowBuyerConfirmation(true);
            }}
            disabled={disabled}
          >
            {t('addBuyer')}
          </Button>
        </div>
      ) : (
        ''
      )}
      {element.element_id === ADDITIONAL_SELLER_PARENT_ID && radioValue === 'Yes' && isBorrower ? (
        <div className='d-flex justify-end plt-8 w-100p'>
          <Button
            variant='outlined'
            style={{ display: 'flex', height: '30px', marginBottom: '12px' }}
            onClick={() => {
              setShowBorrowerConfirmation(true);
            }}
            disabled={disabled}
          >
            {t('addBorrower')}
          </Button>
        </div>
      ) : (
        ''
      )}

      {/* Need to refactor common modal for adding HOA and participants. */}
      <CommonModal
        open={showConfirmation}
        setOpen={setShowConfirmation}
        handleSave={addHoa}
        title={t('addHOA')}
        description={t('noteHOA')}
      />
      <CommonModal
        open={showLoanConfirmation}
        setOpen={setShowLoanConfirmation}
        handleSave={addLoans}
        title={t('addLoan')}
        description={t('noteLoan')}
      />
      <CommonModal
        open={showBuyerConfirmation}
        setOpen={setShowBuyerConfirmation}
        handleSave={addParticipants}
        title={t('addBuyer')}
        description={t('noteBuyer')}
      />
      <CommonModal
        open={showBorrowerConfirmation}
        setOpen={setShowBorrowerConfirmation}
        handleSave={addBorrowers}
        title={t('addBorrower')}
        description={t('noteBorrower')}
      />
    </>
  );
};
export default RadioButton;
